/* eslint-disable */
// import Errors from '@/shared/error/errors';
import HelperClass from '@/shared/helperClass';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/database';
import { getDatabase, ref, onValue } from "firebase/database";

export default {
  namespaced: true,

  state: {
    loading: false,
    currentDate: null,
    statistics: {
      itemSales: null,
      newOrders: null,
      totalProducts: null,
      newUsers: null,
      totalSales: null,
      categorySales: null,
    },
  },

  getters: {
    loading: (state) => state.loading,
    currentDate: (state) => state.currentDate,
    statistics: (state) => state.statistics,
  },

  mutations: {
    FETCH_STARTED(state) {
      state.loading = true;
    },
    FETCH_SUCCESS(state) {
      state.loading = false;
    },
    FETCH_ERROR(state) {
      state.loading = false;
    },

    SET_STATISTICS(state, payload) {
      // const percentage = payload.completedTripsCount / payload.tripsCount || 0
      state.statistics = {
        ...payload,
        itemSales: payload.itemSales,
        newOrders: payload.newOrders,
        totalProducts: payload.totalProducts,
        newUsers: payload.newUsers,
        totalSales: payload.totalSales,
        categorySales: payload.categorySales,
        // percentageOfCompletedTrips: parseFloat(percentage).toFixed(2),
      }

      state.loading = false;
    },
    UPDATE_CURRENT_DATE(state, payload) {
      state.currentDate = payload
    }
  },

  actions: {
    async doFetch({ commit }) {
      try {
        commit('FETCH_STARTED');
        const currentDate = HelperClass.getDateNowDetails()
        commit('UPDATE_CURRENT_DATE', currentDate)
        
        const db = getDatabase();
        const statsRef = ref(db, 'stats');
        onValue(statsRef, (snapshot) => {
          const data = snapshot.val();
          commit('SET_STATISTICS', data);
        });
      } catch (error) {
        console.error('stats ERROR', error);
        commit('FETCH_ERROR');
      }
    },
  },
};
