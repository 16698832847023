/* eslint-disable */
import config from '@/config'
import gql from 'graphql-tag'
import GraphqlClient from '@/shared/graphql/client'
const graphqlClient = GraphqlClient.initApolloClient(config.backendUrlEvent)

export class EventService {
//#region [ Mutation APIs ]
  /**
   * Create New Event
   * - createEvent(eventInput: EventInput!): JSON!
   * @param {Object} data
   * @param {String} data.cover
   * @param {DateTime} data.time
   * @param {Object} data.title
   * @param {String} data.title.en
   * @param {String} data.title.ar
   * 
   * @param {Object} data.description
   * @param {String} data.description.en
   * @param {String} data.description.ar
   * 
   * @param {Object} data.eventObjectives
   * @param {String} data.eventObjectives.en
   * @param {String} data.eventObjectives.ar
   * 
   * @param {Object} data.eventTermsAndConditions
   * @param {String} data.eventTermsAndConditions.en
   * @param {String} data.eventTermsAndConditions.ar
   * 
   * @param {Object} data.eventAgenda
   * @param {String} data.eventAgenda.en
   * @param {String} data.eventAgenda.ar
   * 
   * @param {Object} data.eventMission
   * @param {String} data.eventMission.en
   * @param {String} data.eventMission.ar
   * 
   * @param {Object} data.eventVision
   * @param {String} data.eventVision.en
   * @param {String} data.eventVision.ar
   * 
   * @param {Object} data.location
   * @param {Float} data.location.lat
   * @param {Float} data.location.long
   * 
   * @param {Object} data.sponsors
   * @param {String} data.sponsors.logo
   * @param {String} data.sponsors.link
   * 
   * @param {Object} data.responsible
   * @param {String} data.responsible.name
   * @param {String} data.responsible.email
   * @param {String} data.responsible.phoneNumber
   * 
   * @param {Object} data.timeTable
   * @param {Object} data.timeTable.title
   * @param {String} data.timeTable.title.en
   * @param {String} data.timeTable.title.ar
   * @param {Object} data.timeTable.description
   * @param {String} data.timeTable.description.en
   * @param {String} data.timeTable.description.ar
   * @param {Object} data.timeTable.interval
   * @param {String} data.timeTable.interval.en
   * @param {String} data.timeTable.interval.ar
   * @returns {Promise<JSON>}
   */
  static async create(data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation EVENT_CREATE($eventInput: EventInput!) {
          createEvent(eventInput: $eventInput)
        }`,
      variables: {
        eventInput: data
      }
    })

    if (response.data.createEvent && response.data.createEvent.status) {
      return response.data.createEvent.result
    } else {
      console.error(response.data.createEvent.error);
      throw response.data.createEvent.error
    }
  }

  /**
   * Update Event
   * updateEvent(eventId: String!, eventUpdate: EventUpdate!): JSON!
   * @param {String} id
   * @param {Object} data
   * @returns {Promise<JSON>}
   */
  static async update(id, data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation EVENT_UPDATE($eventId: String!, $eventUpdate: EventUpdate!) {
          updateEvent(eventId: $eventId, eventUpdate: $eventUpdate)
        }
      `,

      variables: {
        eventId: id,
        eventUpdate: data
      }
    })

    if (response.data.updateEvent && response.data.updateEvent.status) {
      return response.data.updateEvent.result
    } else {
      console.error(response.data.updateEvent.error);
      throw response.data.updateEvent.error
    }
  }

  /**
   * Delete Event
   * - removeEvent(eventId: String!): JSON!
   * @param {String} eventId 
   * @returns {Promise<JSON>}
   */
  static async destroy(id) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation EVENT_DESTROY($eventId: String!) {
          removeEvent(eventId: $eventId)
        }
      `,

      variables: {
        eventId: id
      }
    })

    if (response.data.removeEvent && response.data.removeEvent.status) {
      return response.data.removeEvent.result
    } else {
      console.error(response.data.removeEvent.error);
      throw response.data.removeEvent.error
    }
  }

  

  /**
   * Join Member To Event
   * - joinEvent(eventId: String!, joinInput: JoinInput!): JSON!
   * @param {String} eventId
   * @param {Object} joinInput
   * @param {String} joinInput.name
   * @param {String} joinInput.phoneNumber
   * @param {Int} joinInput.guestsCount
   * @param {Int} joinInput.kidsCount
   * @returns {Promise<JSON>}
   */
  static async joinEvent(eventId, joinInput) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation EVENT_JOIN($eventId: String!, $joinInput: JoinInput!) {
          joinEvent(eventId: $eventId, joinInput: $joinInput)
        }
      `,

      variables: {
        eventId, 
        joinInput
      }
    })

    if (response.data.joinEvent && response.data.joinEvent.status) {
      return response.data.joinEvent.result
    } else {
      console.error(response.data.joinEvent.error);
      throw response.data.joinEvent.error
    }
  }


  /**
   * Join Member To Event
   * - confirmAttendance(eventId: String!, joinerId: String!): JSON!
   * @param {String} eventId
   * @param {String} joinerId
   * @returns {Promise<JSON>}
   */
   static async confirmAttendance(eventId, joinerId) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation CONFIRM_ATTENDANCE($eventId: String!, $joinerId: String!) {
          confirmAttendance(eventId: $eventId, joinerId: $joinerId)
        }
      `,

      variables: {
        eventId, 
        joinerId
      }
    })

    if (response.data.confirmAttendance && response.data.confirmAttendance.status) {
      return response.data.confirmAttendance.result
    } else {
      console.error(response.data.confirmAttendance.error);
      throw response.data.confirmAttendance.error
    }
  }
  
//#endregion

//#region [ Query APIs ]
  /**
   * Fint Event By ID
   * - findEventById(eventId: String!): Event!
   * @param {String} id - Event ID
   * @returns {Promise<Event>}
   */
  static async find(id) {
    const response = await graphqlClient.query({
      query: gql`
        query EVENT_FIND($eventId: String!) {
          findEventById(eventId: $eventId) {
            id
            cover
            time
            isRemoved

            title {
              en
              ar
            }
            description {
              en
              ar
            }
            eventMission {
              en
              ar
            }
            eventVision {
              en
              ar
            }
            eventObjectives {
              en
              ar
            }
            eventTermsAndConditions {
              en
              ar
            }
            eventAgenda {
              en
              ar
            }
            responsible {
              name
              email
              phoneNumber
            }
            location {
              lat
              long
            }
            sponsors {
              logo
              link
            }
            timeTable {
              title {
                en
                ar
              }
              description {
                en
                ar
              }
              interval {
                en
                ar
              }
            }

            createdBy
            createdAt
            updatedBy
            updatedAt
          }
        }
      `,

      variables: {
        eventId: id
      }
    })

    return response.data.findEventById
  }

  /**
   * Retrieve Events with pagination
   * - listEvents(filter: [Filter]!, pagination: PaginationInput!): [Event]!
   * @param {Object[]} filter
   * @param {String} filter.key
   * @param {String} filter.value
   * @param {String[]} filter.values
   * @param {'less'|'lessEqual'|'greater'|'greaterEqual'|'equal'|'notEqual'|'startsWith|in|notIn|arrayContains|arrayContainsAny'} filter.operator
   * 
   * @param {Object} pagination
   * @param {Boolean} pagination.firstPage
   * @param {Int} pagination.limit
   * @param {JSON} pagination.doc
   * @param {'next'|'prev'} pagination.action
   * @param {'asc'|'desc'} pagination.sortType
   * @param {String} pagination.orderBy
   * @returns {Promise<Event[]>}
   */
  static async list(filter, pagination) {
    const response = await graphqlClient.query({
      query: gql`
        query EVENT_LIST(
          $filter: [Filter]!,
          $pagination: PaginationInput!
        ) {
          listEvents(filter: $filter, pagination: $pagination) {
            id
            cover
            time
            isRemoved

            title {
              en
              ar
            }
            description {
              en
              ar
            }
            eventMission {
              en
              ar
            }
            eventVision {
              en
              ar
            }
            eventObjectives {
              en
              ar
            }
            eventTermsAndConditions {
              en
              ar
            }
            eventAgenda {
              en
              ar
            }
            responsible {
              name
              email
              phoneNumber
            }
            location {
              lat
              long
            }
            sponsors {
              logo
              link
            }
            timeTable {
              title {
                en
                ar
              }
              description {
                en
                ar
              }
              interval {
                en
                ar
              }
            }

            createdBy
            createdAt
            updatedBy
            updatedAt
          }
        }
      `,

      variables: {
        filter,
        pagination
      }
    })

    return response.data.listEvents
  }

  /**
   * Retrieve all members who joined the event with pagination
   * - listJoiners(eventId: String!, filter: [Filter]!, pagination: PaginationInput!): [Joiner]!
   * @param {String} eventId
   * @param {Object[]} filter
   * @param {String} filter.key
   * @param {String} filter.value
   * @param {String[]} filter.values
   * @param {'less'|'lessEqual'|'greater'|'greaterEqual'|'equal'|'notEqual'|'startsWith|in|notIn|arrayContains|arrayContainsAny'} filter.operator
   * 
   * @param {Object} pagination
   * @param {Boolean} pagination.firstPage
   * @param {Int} pagination.limit
   * @param {JSON} pagination.doc
   * @param {'next'|'prev'} pagination.action
   * @param {'asc'|'desc'} pagination.sortType
   * @param {String} pagination.orderBy
   * @returns {Promise<Joiner[]>}
   */
  static async listJoiners(eventId, filter, pagination) {
    const response = await graphqlClient.query({
      query: gql`
        query EVENT_MEMBERS_LIST(
          $eventId: String!,
          $filter: [Filter]!,
          $pagination: PaginationInput!
        ) {
          listJoiners(eventId: $eventId, filter: $filter, pagination: $pagination) {
            id
            name
            phoneNumber
            guestsCount
            kidsCount
            isAttended
            
            createdBy
            createdAt
            updatedBy
            updatedAt
          }
        }
      `,

      variables: {
        eventId,
        filter,
        pagination
      }
    })

    return response.data.listJoiners
  }
//#endregion
}
