/* eslint-disable */
import { createRouter, createWebHistory } from 'vue-router'
import Permissions from '@/security/permissions'

//#region [ Main Pages ]
const SideMenu = () => import('@/layouts/side-menu/Main.vue')
const ErrorPage = () => import('@/views/error-page/Main.vue')
const Error403Page = () => import('@/views/error-page/error-403-page.vue')
const Error404Page = () => import('@/views/error-page/error-404-page.vue')
const Error500Page = () => import('@/views/error-page/error-500-page.vue')
//#endregion

const Login = () => import('@/views/auth/signin-page.vue')
const ForgotPassword = () => import('@/views/auth/forgot-password.vue')
const Profile = () => import('@/views/profile/profile.vue')
const ChangePassword = () => import('@/views/profile/change-password.vue')

const HomePage = () => import('@/views/home/home-page.vue')

const AdminListPape = () => import('@/views/admin/admin-list-page.vue')
const AdminFormPage = () => import('@/views/admin/admin-form-page.vue')
const AdminViewPage = () => import('@/views/admin/admin-view-page.vue')
const AdminAccessPage = () => import('@/views/admin/admin-access-page.vue')

const UsersListPage = () => import('@/views/users/user-list-page.vue')
const UserViewPage = () => import('@/views/users/user-view-page.vue')

const EventListPage = () => import('@/views/event/event-list-page.vue')
const EventFormPage = () => import('@/views/event/event-form-page.vue')
const EventViewPage = () => import('@/views/event/event-view-page.vue')
const EventAttendeeListPage = () => import('@/views/event/event-list-attendees.vue')

const Settings = () => import('@/views/settings/settings-list-page.vue')

const routes = [
  {
    path: '',
    component: SideMenu,
    meta: { auth: true },
    children: [
      {
        name: 'home',
        path: '',
        redirect: '/admins',
        component: HomePage,
        meta: {
          auth: true
        }
      },
      {
        name: 'profile',
        path: 'profile',
        component: Profile,
        meta: {
          auth: true,
        },
      },
      {
        name: 'changePassword',
        path: 'change-password',
        component: ChangePassword,
        meta: {
          auth: true,
        },
      },
      {
        name: 'admins',
        path: 'admins',
        component: AdminListPape,
        meta: {
          auth: true,
          permission: Permissions.values.adminRead
        }
      },
      {
        name: 'adminNew',
        path: 'admins/new',
        component: AdminFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.adminCreate
        }
      },
      {
        name: 'adminEdit',
        path: 'admins/:id/edit',
        props: true,
        component: AdminFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.adminEdit
        }
      },
      {
        name: 'adminView',
        path: 'admins/:id',
        component: AdminViewPage,
        props: true,
        meta: {
          auth: true
          // permission: Permissions.values.adminRead
        }
      },
      {
        name: 'adminAccessPages',
        path: 'admins/:id/access-pages',
        component: AdminAccessPage,
        props: true,
        meta: {
          auth: true,
          permission: Permissions.values.pagesAccessRead
        }
      },
      {
        name: 'users',
        path: 'users',
        component: UsersListPage,
        meta: {
          auth: true,
          permission: Permissions.values.userRead
        }
      },
      {
        name: 'userView',
        path: 'users/:id',
        props: true,
        component: UserViewPage,
        meta: {
          auth: true,
          permission: Permissions.values.userRead
        },
      },
      {
        name: 'events',
        path: 'events',
        component: EventListPage,
        meta: {
          auth: true,
          permission: Permissions.values.eventRead
        }
      },
      {
        name: 'eventNew',
        path: 'events/new',
        component: EventFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.eventCreate
        }
      },
      {
        name: 'eventEdit',
        path: 'events/:id/edit',
        props: true,
        component: EventFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.eventEdit
        }
      },
      {
        name: 'eventView',
        path: 'events/:id',
        component: EventViewPage,
        props: true,
        meta: {
          auth: true,
          permission: Permissions.values.eventRead
        }
      },
      {
        name: 'eventAttendee',
        path: 'events/:id/attendees',
        component: EventAttendeeListPage,
        props: true,
        meta: {
          auth: true,
          permission: Permissions.values.eventRead
        }
      },
      {
        name: 'settings',
        path: 'settings',
        component: Settings,
        meta: {
          auth: true,
          permission: Permissions.values.settingRead
        }
      }
    ]
  },
  {
    name: 'login',
    path: '/auth/login',
    component: Login,
    meta: { unauth: true }
  },
  {
    name: 'forgotPassword',
    path: '/auth/forgot-password',
    component: ForgotPassword,
    meta: { unauth: true }
  },
  // {
  //   name: 'register',
  //   path: '/auth/register',
  //   component: Register,
  //   meta: { unauth: true }
  // },
  {
    name: 'errorPage',
    path: '/error-page',
    component: ErrorPage
  },
  {
    name: 'error403',
    path: '/403',
    component: Error403Page
  },
  {
    name: 'error404',
    path: '/404',
    component: Error404Page
  },
  {
    name: 'error500',
    path: '/500',
    component: Error500Page
  },
  {
    path: '/:pathMatch(.*)*',
    component: ErrorPage
  }
  // { path: '*', redirect: '/404' }
]

// console.log('Routes =', routes)
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { left: 0, top: 0 }
  }
})

import authGuard from '@/router/auth-guard'
router.beforeResolve(async (to, from, next) => {
  await authGuard.beforeRouteEnter(to, from, next)
})

export function getPages(pages = routes) {
  const routesPages = []
  for (let i = 0; i < pages.length; i++) {
    const route = pages[i]
    if (route.name) {
      routesPages.push({ name: route.name, checked: false })
    }

    if (route.children && route.children.length) {
      const result = getPages(route.children, 0)
      routesPages.push(...result)
    }
  }
  return routesPages
}
export default router
