<template>
  <div id="success-notify-content" class="toastify-content hidden flex">
    <CheckCircleIcon class="text-theme-10" style="color: green !important;" />
    <div class="ml-4 mr-4">
      <div class="font-medium" style="color: green !important;">{{ i18n('common.success') }}</div>
      <div id="error-message-text" class="text-gray-600 mt-1">{{ i18n(message) }}</div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    message: {
      type: String
    }
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args)
    }
  }
})
</script>

<style></style>
