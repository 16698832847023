/* eslint-disable */
import _values from 'lodash/values';
import { i18n } from '@/locales/i18n'
// let pagesJson = null

/**
 * List of Roles available for the Users.
 */
class PagesAccess {
  // static get pages() {
  //   return {
  //     admin: {
  //       view: 'viewAdmins',
  //       create: 'createAdmin',
  //       edit: 'editAdmin',
  //       delete: 'deleteAdmin',
  //       changeStatus: 'changeAdminStatus',
  //       print: 'printAdminsTable',
  //       export: 'exportAdminsTable',
  //     },
  //     user: {
  //       view: 'viewUsers',
  //       delete: 'deleteUser',
  //       changeStatus: 'changeUserStatus',
  //       print: 'printUsersTable',
  //       export: 'exportUsersTable',
  //     },
  //     category: {
  //       view: 'viewCategories',
  //       create: 'createCategory',
  //       edit: 'editCategory',
  //       delete: 'deleteCategory',
  //       print: 'printCategoriesTable',
  //       export: 'exportCategoriesTable',
  //     },
  //     subcategory: {
  //       view: 'viewSubcategories',
  //       create: 'createSubcategory',
  //       edit: 'editSubcategory',
  //       delete: 'deleteSubcategory',
  //       print: 'printSubcategoriesTable',
  //       export: 'exportSubcategoriesTable',
  //     },
  //     brand: {
  //       view: 'viewBrands',
  //       create: 'createBrand',
  //       edit: 'editBrand',
  //       delete: 'deleteBrand',
  //       print: 'printBrandsTable',
  //       export: 'exportBrandsTable',
  //     },
  //     product: {
  //       view: 'viewProducts',
  //       edit: 'editProduct',
  //       delete: 'deleteProduct',
  //     },
  //     order: {
  //       view: 'viewOrders',
  //       edit: 'editOrder',
  //       action: 'takeOrderAction',
  //       delete: 'deleteOrder',
  //     },
  //     request: {
  //       view: 'viewRequests',
  //       edit: 'editRequest',
  //       action: 'takeRequestAction',
  //       print: 'printRequestsTable',
  //       export: 'exportRequestsTable',
  //     },
  //     wallet: {
  //       view: 'viewWallets',
  //       edit: 'editWallet',
  //       action: 'takeWalletAction',
  //       print: 'printWalletsTable',
  //       export: 'exportWalletsTable',
  //     },
  //     notification: {
  //       view: 'viewNotifications',
  //       create: 'createNotification',
  //       send: 'sendNotification',
  //     },
  //     promoCode: {
  //       view: 'viewPromoCodes',
  //       create: 'createPromoCode',
  //       edit: 'editPromoCode',
  //       action: 'takePromoCodeAction',
  //       print: 'printPromoCodesTable',
  //       export: 'exportPromoCodesTable',
  //     },
  //     slider: {
  //       view: 'viewSliders',
  //       create: 'createSlider',
  //       edit: 'editSlider',
  //       delete: 'deleteSlider',
  //     },
  //     report: {
  //       view: 'viewReports',
  //       viewSales: 'viewSalesReports',
  //       viewCities: 'viewCitiesReports',
  //     },
  //     complaint: {
  //       view: 'viewComplaints',
  //     },
  //     setting: {
  //       view: 'viewSettings',
  //       general: 'viewGeneralSettings',
  //       createGeneral: 'createGeneralSettings',
  //       shipping: 'viewShippingSettings',
  //       createShipping: 'createShippingSettings',
  //       policy: 'viewPolicySettings',
  //       createPolicy: 'createPolicySettings',
  //     },
  //   };
  // }

  // static get values() {
  //   if (!pagesJson) {
  //     pagesJson = {}
  //     Object.keys(this.pages).forEach(element => {
        
  //     });
  //   }

  //   return pagesJson
  // }


  static get values() {
    return {
      setAccessPermissions: "setAccessPermissions",
      viewAdmins : "viewAdmins",
      createAdmin: "createAdmin",
      editAdmin: "editAdmin",
      deleteAdmin: "deleteAdmin",
      changeAdminStatus: "changeAdminStatus",
      printAdminsTable : "printAdminsTable",
      exportAdminsTable: "exportAdminsTable",

      viewUsers: "viewUsers",
      deleteUser : "deleteUser",
      changeUserStatus : "changeUserStatus",
      printUsersTable: "printUsersTable",
      exportUsersTable : "exportUsersTable",
      
      viewEvents : "viewEvents",
      createEvent: "createEvent",
      editEvent: "editEvent",
      deleteEvent: "deleteEvent",
      // changeEventStatus: "changeEventStatus",
      // printEventsTable : "printEventsTable",
      // exportEventsTable: "exportEventsTable",

      // viewComplaints : "viewComplaints",
      viewSettings : "viewSettings",
      viewGeneralSettings: "viewGeneralSettings",
      createGeneralSettings: "createGeneralSettings",
      viewPolicySettings : "viewPolicySettings",
      createPolicySetting: "createPolicySetting",
    };
  }

  static labelOf(pageId) {
    if (!this.values[pageId]) {
      return pageId;
    }

    return i18n(`pagesAccess.${pageId}`);
  }

  static get selectOptions() {
    return _values(this.values).map((value) => ({
      id: value,
      value: value,
      // title: this.descriptionOf(value),
      label: this.labelOf(value),
      selected: false,
    }));
  }
}

export default PagesAccess;
