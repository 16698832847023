/* eslint-disable */     
import { EventService } from '@/store/event/event-service';
import Message from '@/shared/message/toastify';
import Errors from '@/shared/error/errors';
import { i18n } from '@/locales/i18n';
// import { routerAsync } from '@/app-module';
import router from '@/router'
// import FirebaseRepository from '@/shared/firebase/firebase-repository';
// import PermissionChecker from '@/security/permission-checker';
// import Permissions from '@/security/permissions';
import { FileUploader } from '@/shared/uploader/file-uploader'

export default {
  namespaced: true,

  state: {
    findLoading: false,
    saveLoading: false,
    record: null,
  },

  getters: {
    record: (state) => state.record,
    findLoading: (state) => !!state.findLoading,
    saveLoading: (state) => !!state.saveLoading,
  },

  mutations: {
    RESET(state) {
      state.findLoading = false;
      state.saveLoading = false;
      state.record = null;
    },

    FIND_STARTED(state) {
      state.record = null;
      state.findLoading = true;
    },
    FIND_SUCCESS(state, payload) {
      state.record = payload;
      state.findLoading = false;
    },
    FIND_ERROR(state) {
      state.record = null;
      state.findLoading = false;
    },

    CREATE_STARTED(state) {
      state.saveLoading = true;
    },
    CREATE_SUCCESS(state) {
      state.saveLoading = false;
    },
    CREATE_ERROR(state) {
      state.saveLoading = false;
    },

    UPDATE_STARTED(state) {
      state.saveLoading = true;
    },
    UPDATE_SUCCESS(state) {
      state.saveLoading = false;
    },
    UPDATE_ERROR(state) {
      state.saveLoading = false;
    },
  },

  actions: {
    doNew({ commit }) {
      commit('RESET');
    },

    async doFind({ commit }, id) {
      try {
        commit('FIND_STARTED');
        const record = await EventService.find(id);
        commit('FIND_SUCCESS', record);
      } catch (error) {
        Errors.handle(error);
        commit('FIND_ERROR');
        router.push('/events');
      }
    },

    async doCreate({ commit }, { coverFile, values }) {
      try {
        commit('CREATE_STARTED');
        // [1] Upload the cover image of event to storage of firebase
        const path = `events/covers`
        const coverImg = coverFile ? await FileUploader.upload(path, coverFile) : { publicUrl: null }

        // [2] Upload the sponsors logos to storage of storage
        const sponsors = []
        const path2 = `events/sponsors`
        for (let index = 0; index < values.sponsors.length; index++) {
          const sponsor = values.sponsors[index];
          const { publicUrl } = sponsor.logoFile
            ? await FileUploader.upload(path2, sponsor.logoFile)
            : { publicUrl: sponsor.logo || null }
          
          sponsors.push({
            logo: publicUrl,
            link: sponsor.link
          })
        }

        // [3] Create Event 
        values['cover'] = coverImg.publicUrl
        values['sponsors'] = sponsors
        await EventService.create(values);

        commit('CREATE_SUCCESS');
        Message.success(i18n('event.create.success'));
        router.push('/events');
      } catch (error) {
        Errors.handle(error);
        commit('CREATE_ERROR');
      }
    },

    async doUpdate({ commit }, { id, values, coverFile }) {
      try {
        commit('UPDATE_STARTED');
        // [1] Upload the cover image of event to storage of firebase
        const path = `events/covers`
        const coverImg = coverFile ? await FileUploader.upload(path, coverFile) : { publicUrl: values.cover }

        // [2] Upload the sponsors logos to storage of storage
        const sponsors = []
        const path2 = `events/sponsors`
        for (let index = 0; index < values.sponsors.length; index++) {
          const sponsor = values.sponsors[index];
          const { publicUrl } = sponsor.logoFile
            ? await FileUploader.upload(path2, sponsor.logoFile)
            : { publicUrl: sponsor.logo || null }
          
          sponsors.push({
            logo: publicUrl,
            link: sponsor.link
          })
        }

        // [3] Update Event 
        values['cover'] = coverImg.publicUrl
        values['sponsors'] = sponsors
        await EventService.update(id, values);

        commit('UPDATE_SUCCESS');
        Message.success(i18n('event.update.success'));
        router.push('/events');
      } catch (error) {
        Errors.handle(error);
        commit('UPDATE_ERROR');
      }
    },
  },
};
