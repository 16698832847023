/* eslint-disable */

// import Roles from '@/security/roles';
// const roles = Roles.values;
import PagesAccess from '@/security/pages-access';
const pagesAccess = PagesAccess.values;

/**
 * List of Permissions and the Roles allowed of using them.
 */
class Permissions {
  static get values() {
    return {
      pagesAccessRead: pagesAccess.setAccessPermissions,
      adminRead: pagesAccess.viewAdmins,
      adminCreate: pagesAccess.createAdmin,
      adminEdit: pagesAccess.editAdmin,
      adminDestroy: pagesAccess.deleteAdmin,
      adminChangeStatus: pagesAccess.changeAdminStatus,
      adminPrint: pagesAccess.printAdminsTable,
      adminExport: pagesAccess.exportAdminsTable,

      userRead: pagesAccess.viewUsers,
      userDestroy: pagesAccess.deleteUser,
      userChangeStatus: pagesAccess.changeUserStatus,
      userPrint: pagesAccess.printUsersTable,
      userExport: pagesAccess.exportUsersTable,

      eventRead: pagesAccess.viewEvents,
      eventCreate: pagesAccess.createEvent,
      eventEdit: pagesAccess.editEvent,
      eventDestroy: pagesAccess.deleteEvent,

      complaintRead: pagesAccess.viewComplaints,

      settingRead: pagesAccess.viewSettings,
      generalSettingRead: pagesAccess.viewGeneralSettings,
      generalSettingCreate: pagesAccess.createGeneralSettings,
      shippingSettingRead: pagesAccess.viewShippingSettings,
      shippingSettingCreate: pagesAccess.createShippingSettings,
      policyRead: pagesAccess.viewPolicySettings,
      policyCreate: pagesAccess.createPolicySetting,
    };
  }

  static get asArray() {
    return Object.keys(this.values).map(key => {
      return this.values[key];
    });
  }
}

export default Permissions;
