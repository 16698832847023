/* eslint-disable */
import HelperClass from '@/shared/helperClass'
import _get from 'lodash/get'
import en from './en.json'
import ar from './ar.json'
import vueI18n from '../vueI18n'

import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
// import 'firebase/compat/auth' // for authentication

let currentLanguageCode = null;

const languages = {
  en: {
    id: 'en',
    label: 'English',
    flag: '/images/flags/24/englishLang.svg',
    secondFlag: '/images/flags/24/englishRtl.svg',
    dictionary: en,
  },
  ar: {
    id: 'ar',
    label: 'العربية',
    flag: '/images/flags/24/arabicLang.svg',
    secondFlag: '/images/flags/24/arabicRtl.svg',
    dictionary: ar,
  },
};

async function init() {
  const currentUser = HelperClass.fetchLocalCurrentUser()
  const currentUserLanguage = currentUser ? currentUser.lang : null
  const languageInStorage = localStorage.getItem('language')

  currentLanguageCode = currentUserLanguage ? currentUserLanguage : languageInStorage || 'ar';

  if (currentUserLanguage != currentLanguageCode) {
    currentUser['lang'] = currentLanguageCode;
    HelperClass.saveLocalCurrentUser(currentUser)
    firebase.firestore().collection('users').doc(currentUser.id).update({
      lang: currentLanguageCode
    })
  }

  if (languageInStorage != currentLanguageCode) {
    localStorage.setItem('language', currentLanguageCode)
  }

  setLanguageCode(currentLanguageCode, true);
}

function format(message, args) {
  if (!message) {
    return null;
  }

  try {
    return message.replace(/{(\d+)}/g, function (match, number) {
      return typeof args[number] != 'undefined'
        ? args[number]
        : match
    });
  } catch (error) {
    console.error(message, error);
    throw error;
  }
}

function getLanguage() {
  return languages[getLanguageCode()];
}
export function getLanguages() {
  return Object.keys(languages).map((language) => {
    return languages[language];
  });
}
export function getLanguageCode() {
  if (!currentLanguageCode) {
    init();
  }
  
  // return vueI18n.global.locale
  return currentLanguageCode;
}
export function setLanguageCode(arg, updatedCurrentUser = false) {
  if (!languages[arg]) {
    throw new Error(`Invalid language ${arg}.`);
  }

  // moment.locale(arg);
  currentLanguageCode = arg
  vueI18n.global.locale = arg
  localStorage.setItem('language', arg);
  // store.dispatch('layout/changeCurrentLanguageCode', arg);

  if (!updatedCurrentUser) {
    const currentUser = HelperClass.fetchLocalCurrentUser()

    if (currentUser && currentUser.lang != currentLanguageCode) {
      currentUser['lang'] = currentLanguageCode;
      HelperClass.saveLocalCurrentUser(currentUser)
      firebase.firestore().collection('users').doc(currentUser.id).update({
        lang: currentLanguageCode
      })
    }
  }

  // if (getLanguage().dictionary.validation) {
  //   setYupLocale(getLanguage().dictionary.validation);
  // }
}
export function i18nExists(key) {
  const message = _get(getLanguage().dictionary, key);
  return !!message;
}
export function i18n(key, ...args) {
  // const message = _get(getLanguage().dictionary, key);
  const message = vueI18n.global.t(key, args);

  if (!message) {
    return key;
  }

  return format(message, args);
}
export function isRTL() {
  return getLanguageCode() === 'ar'
}