/* eslint-disable */     
import { EventService } from '@/store/event/event-service';
import Message from '@/shared/message/toastify';
import Errors from '@/shared/error/errors';
import { i18n } from '@/locales/i18n';
import router from '@/router'

export default {
  namespaced: true,

  state: {
    findLoading: false,
    saveLoading: false,
    record: null,
  },

  getters: {
    record: (state) => state.record,
    findLoading: (state) => !!state.findLoading,
    saveLoading: (state) => !!state.saveLoading,
  },

  mutations: {
    RESET(state) {
      state.findLoading = false;
      state.saveLoading = false;
      state.record = null;
    },

    FIND_STARTED(state) {
      state.record = null;
      state.findLoading = true;
    },
    FIND_SUCCESS(state, payload) {
      state.record = payload;
      state.findLoading = false;
    },
    FIND_ERROR(state) {
      state.record = null;
      state.findLoading = false;
    },

    CREATE_STARTED(state) {
      state.saveLoading = true;
    },
    CREATE_SUCCESS(state) {
      state.saveLoading = false;
    },
    CREATE_ERROR(state) {
      state.saveLoading = false;
    },

    UPDATE_STARTED(state) {
      state.saveLoading = true;
    },
    UPDATE_SUCCESS(state) {
      state.saveLoading = false;
    },
    UPDATE_ERROR(state) {
      state.saveLoading = false;
    },
  },

  actions: {
    doNew({ commit }) {
      commit('RESET');
    },

    // async doFind({ commit }, id) {
    //   try {
    //     commit('FIND_STARTED');
    //     const record = await EventService.find(id);
    //     commit('FIND_SUCCESS', record);
    //   } catch (error) {
    //     Errors.handle(error);
    //     commit('FIND_ERROR');
    //     router.push('/events');
    //   }
    // },

    async doCreate({ commit, dispatch, rootGetters }, { id, values, route = false }) {
      try {
        commit('CREATE_STARTED');
        await EventService.joinEvent(id, values);
        commit('CREATE_SUCCESS');
        Message.success(i18n('attendees.create.success'));

        if (route) {
          router.push('/attendee');
        } else {
          dispatch(`attendee/list/doFetch`, {
            eventId: rootGetters[`attendee/list/eventId`], 
            filter: rootGetters[`attendee/list/filter`], 
            pagination: rootGetters[`attendee/list/pagination`]
          }, { root: true })
        }
      } catch (error) {
        Errors.handle(error);
        commit('CREATE_ERROR');
      }
    },

    // async doUpdate({ commit }, { id, values }) {
    //   try {
    //     commit('UPDATE_STARTED');
    //     await EventService.edit(id, values);
    //     commit('UPDATE_SUCCESS');
    //     Message.success(i18n('attendees.update.success'));
    //     router.push('/events');
    //   } catch (error) {
    //     Errors.handle(error);
    //     commit('UPDATE_ERROR');
    //   }
    // },

    async doConfirm({ commit, dispatch, rootGetters }, { eventId, joinerId, route = false }) {
      try {
        commit('UPDATE_STARTED');
        debugger
        await EventService.confirmAttendance(eventId, joinerId);
        commit('UPDATE_SUCCESS');
        Message.success(i18n('attendees.confirm.success'));
        
        if (route) {
          router.push('/attendee');
        } else {
          dispatch(`attendee/list/doFetch`, {
            eventId: rootGetters[`attendee/list/eventId`], 
            filter: rootGetters[`attendee/list/filter`], 
            pagination: rootGetters[`attendee/list/pagination`]
          }, { root: true })
        }
      } catch (error) {
        Errors.handle(error);
        commit('UPDATE_ERROR');
      }
    },
  },
};
