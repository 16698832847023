/* eslint-disable */
import { UserService } from '@/store/user/user-service';
import Message from '@/shared/message/toastify';
import Errors from '@/shared/error/errors';
import { i18n } from '@/locales/i18n';

const INITIAL_PAGE_SIZE = 10;
const INITIAL_PAGINATION_INPUT = {
  limit: INITIAL_PAGE_SIZE,
  orderBy: 'createdAt',
  sortType: 'desc',
  action: 'next',
  firstPage: true,
  doc: {},
}

export default {
  namespaced: true,

  state: {
    rows: [],
    count: 0,
    loading: false,
    filter: [],
    currentPage: 1,
    pagination: INITIAL_PAGINATION_INPUT,
    sorter: {},
    table: null,
    type: null,
  },

  getters: {
    loading: (state) => state.loading,
    exportLoading: (state) => state.exportLoading,
    rows: (state) => state.rows || [],
    count: (state) => state.count,
    hasRows: (state, getters) => getters.count > 0,
    filter: (state) => state.filter,
    currentPage: (state) => state.currentPage,
    type: (state) => state.type,
    table: (state) => state.table,
    
    orderBy: (state) => {
      const sorter = state.sorter;

      if (!sorter) {
        return null;
      }

      if (!sorter.prop) {
        return null;
      }

      let direction =
        sorter.order === 'descending' ? 'DESC' : 'ASC';

      return `${sorter.prop}_${direction}`;
    },

    limit: (state) => {
      const pagination = state.pagination;

      if (!pagination || !pagination.pageSize) {
        return INITIAL_PAGE_SIZE;
      }

      return pagination.pageSize;
    },

    offset: (state) => {
      const pagination = state.pagination;

      if (!pagination || !pagination.pageSize) {
        return 0;
      }

      const currentPage = pagination.currentPage || 1;

      return (currentPage - 1) * pagination.pageSize;
    },

    pagination: (state, getters) => {
      return {
        ...state.pagination,
        currentPage: state.currentPage,
        total: getters.count,
        showSizeChanger: true,
      };
    },

    selectedRows: (state) => {
      return state.table ? state.table.selection : [];
    },
  },

  mutations: {
    RESETED(state) {
      state.rows = [];
      state.count = 0;
      state.loading = false;
      state.filter = [];
      state.pagination = INITIAL_PAGINATION_INPUT;
      state.sorter = {};
      state.currentPage = 1
      // state.type = null
      if (state.table) {
        state.table.clearSelection();
      }
    },

    UNSELECT_ALL(state) {
      if (state.table) {
        state.table.clearSelection();
      }
    },

    TABLE_MOUNTED(state, payload) {
      state.table = payload;
    },

    PAGINATION_CHANGED(state, payload) {
      state.pagination = payload || INITIAL_PAGINATION_INPUT;
    },

    PAGINATION_CURRENT_PAGE_CHANGED(state, payload) {
      state.currentPage = payload && payload > 0
        ? payload
        : state.currentPage || 1 
      
      // const previousPagination = state.pagination || INITIAL_PAGINATION_INPUT;
      // state.pagination = {
      //   ...previousPagination,
      //   currentPage: payload || 1,
      //   pageSize: previousPagination.pageSize || INITIAL_PAGE_SIZE,
      // };
    },

    PAGINATION_PAGE_SIZE_CHANGED(state, payload) {
      const previousPagination = state.pagination || INITIAL_PAGINATION_INPUT;

      state.currentPage = state.currentPage || 1
      state.pagination = {
        ...previousPagination,
        limit: payload || INITIAL_PAGE_SIZE,
        // currentPage: previousPagination.currentPage || 1,
        // pageSize: payload || INITIAL_PAGE_SIZE,
      };
    },

    SORTER_CHANGED(state, payload) {
      state.sorter = payload || {};
    },

    FETCH_STARTED(state, payload) {
      state.loading = true;

      if (state.table) {
        state.table.clearSelection();
      }

      state.type = payload && payload.type ? payload.type : state.type
      state.filter = payload && payload.filter ? payload.filter : state.filter;
      state.pagination = payload && payload.pagination
        ? payload.pagination
        : payload.keepPagination ? state.pagination : {};
    },

    FETCH_SUCCESS(state, payload) {
      state.loading = false;
      state.rows = payload.rows;
      state.count = payload.count;
    },

    FETCH_ERROR(state) {
      state.loading = false;
      state.rows = [];
      state.count = 0;
    },

    EXPORT_STARTED(state) {
      state.exportLoading = true;
    },

    EXPORT_SUCCESS(state) {
      state.exportLoading = false;
    },

    EXPORT_ERROR(state) {
      state.exportLoading = false;
    },

    REMOVE_ALL_SELECTED_STARTED(state) {
      state.loading = true;
    },

    REMOVE_ALL_SELECTED_ERROR(state) {
      state.loading = false;
    },

    REMOVE_ALL_SELECTED_SUCCESS(state) {
      if (state.table) {
        state.table.clearSelection();
      }
    },

    CHANGE_STATUS_SELECTED_STARTED(state) {
      state.loading = true;
    },

    CHANGE_STATUS_SELECTED_ERROR(state) {
      state.loading = false;
    },

    CHANGE_STATUS_SELECTED_SUCCESS(state) {
      if (state.table) {
        state.table.clearSelection();
      }
    },
    UPDATE_USER_TYPE(state, payload) {
      state.type = payload
    }
  },

  actions: {
    doUnselectAll({ commit }) {
      commit('UNSELECT_ALL');
    },

    doMountTable({ commit }, table) {
      console.log(table);
      commit('TABLE_MOUNTED', table);
    },

    async doReset({ commit, dispatch }) {
      commit('RESETED');
      return dispatch('doFetch');
    },

    // doChangePagination({ commit, getters, dispatch }, pagination) {
    //   commit('PAGINATION_CHANGED', pagination);
    //   const filter = getters.filter;
    //   dispatch('doFetch', { filter, keepPagination: true });
    // },

    doChangePaginationPageSize({ commit, getters, dispatch }, pageSize) {
      commit('PAGINATION_PAGE_SIZE_CHANGED', pageSize);
      const filter = getters.filter;
      const pagination = getters.pagination;
      const type = getters.type;
      dispatch('doFetch', { filter, pagination, type, keepPagination: true });
    },

    doChangePaginationCurrentPage({ commit }, currentPage) {
      commit('PAGINATION_CURRENT_PAGE_CHANGED', currentPage);
      // const filter = getters.filter;
      // dispatch('doFetch', { filter, keepPagination: true });
    },

    // doChangeSort({ commit, getters, dispatch }, sorter) {
    //   commit('SORTER_CHANGED', sorter);
    //   const filter = getters.filter;
    //   dispatch('doFetch', { filter, keepPagination: true });
    // },
    doSetUserType({ commit }, type) {
      commit('UPDATE_USER_TYPE', type)
    },

    async doFetchNextPage({ commit, getters, dispatch }) {
      const rows = getters.rows || []
      if (rows.length) {
        const currentPage = getters.currentPage || 1
        const previousPagination = getters.pagination || INITIAL_PAGINATION_INPUT
        const lastDocument = rows[rows.length - 1]
        const pagination = {
          ...previousPagination,
          action: 'next',
          firstPage: false,
          doc: lastDocument
        };
        commit('PAGINATION_CURRENT_PAGE_CHANGED', currentPage + 1)
        await dispatch('doFetch', { pagination, type: getters.type })
      }
    },
    async doFetchPreviousPage({ commit, getters, dispatch }) {
      const rows = getters.rows || []
      const currentPage = getters.currentPage || 1
      if (currentPage > 1 && rows.length) {
        const previousPagination = getters.pagination || INITIAL_PAGINATION_INPUT
        const firstDocument = rows[0]
        const pagination = {
          ...previousPagination,
          action: 'prev',
          firstPage: false,
          doc: firstDocument
        };
        commit('PAGINATION_CURRENT_PAGE_CHANGED', currentPage - 1)
        await dispatch('doFetch', { pagination, type: getters.type })
      } 
    },
    async doFetch(
      { commit, getters },
      { filter, pagination, type, keepPagination = true } = {},
    ) {
      try {
        if (type == 'admin' || type == 'user') {
          commit('FETCH_STARTED', { filter, pagination, type, keepPagination })

          const PaginationInput = {
            limit: pagination.limit || getters.pagination.limit,
            doc: pagination.doc || getters.pagination.doc,
            action: pagination.action || getters.pagination.action,
            orderBy: pagination.orderBy || getters.pagination.orderBy,
            sortType: pagination.sortType || getters.pagination.sortType,
            firstPage: pagination.firstPage 
          }
          const response = type == 'admin' 
            ? await UserService.listAdmins(getters.filter, PaginationInput)
            : await UserService.listUsers(getters.filter, PaginationInput)
  
          commit('FETCH_SUCCESS', {
            rows: response,
            count: response.length,
          })
        }
      } catch (error) {
        console.log('ERROR =>', error);
        Errors.handle(error);
        commit('FETCH_ERROR');
      }
    },
    async doDisableAllSelected({ getters, commit, dispatch }, ids) {
      try {
        // const selectedRows = getters.selectedRows;
        const selectedRows = ids

        commit('CHANGE_STATUS_SELECTED_STARTED');

        // await UserService.disable(selectedRows.map((user) => user.id));
        await UserService.disable(selectedRows);

        commit('CHANGE_STATUS_SELECTED_SUCCESS');

        Message.success(i18n('iam.doDisableAllSuccess'));

        dispatch('doFetch', {
          filter: getters.filter,
          pagination: getters.pagination,
          type: getters.type
        });
      } catch (error) {
        Errors.handle(error);
        commit('CHANGE_STATUS_SELECTED_ERROR');
        dispatch('doFetch', {
          filter: getters.filter,
          pagination: getters.pagination,
          type: getters.type
        });
      }
    },
    async doEnableAllSelected({ commit, getters, dispatch }, ids) {
      try {
        // const selectedRows = getters.selectedRows;
        const selectedRows = ids

        commit('CHANGE_STATUS_SELECTED_STARTED');

        // await UserService.enable(selectedRows.map((user) => user.id));
        await UserService.enable(selectedRows);

        commit('CHANGE_STATUS_SELECTED_SUCCESS');

        Message.success(i18n('iam.doEnableAllSuccess'));

        dispatch('doFetch', {
          filter: getters.filter,
          pagination: getters.pagination,
          type: getters.type
        });
      } catch (error) {
        Errors.handle(error);

        commit('CHANGE_STATUS_SELECTED_ERROR');

        dispatch('doFetch', {
          filter: getters.filter,
          pagination: getters.pagination,
          type: getters.type
        });
      }
    },

    // async doRemoveAllSelected({ commit, getters, dispatch }) {
    //   try {
    //     const selectedRows = getters.selectedRows;

    //     commit('REMOVE_ALL_SELECTED_STARTED');

    //     await UserService.remove(
    //       selectedRows.map((row) => row.email),
    //       [],
    //       true,
    //     );

    //     dispatch('REMOVE_ALL_SELECTED_SUCCESS');

    //     Message.success(i18n('iam.doRemoveAllSelectedSuccess'));

    //     const filter = getters.filter;
    //     dispatch('doFetch', { filter });
    //   } catch (error) {
    //     Errors.handle(error);

    //     commit('REMOVE_ALL_SELECTED_ERROR');

    //     const filter = getters.filter;
    //     dispatch('doFetch', { filter });
    //   }
    // },
    // async doDestroy({ commit }, id) {
    //   try {
    //     await UserService.destroy(id);
    //     Message.success(i18n('entities.admin.destroy.success'));
    //   } catch (error) {
    //     console.log(commit);
    //     Errors.handle(error);
    //   }
    // },
  },
};
