/* eslint-disable */ 
// import { routerAsync } from '@/app-module';
import { UserService } from '@/store/user/user-service';
// import Message from '@/shared/message/toastify';
import Errors from '@/shared/error/errors';
// import { i18n } from '@/locales/i18n';
import router from '@/router';

// import lodash from 'lodash';
import FirebaseRepository from '@/shared/firebase/firebase-repository';
// import { firestoreAction, vuexfireMutations } from 'vuexfire'
// import firebase from 'firebase/compat/app';
// import 'firebase/compat/firestore'

export default {
  namespaced: true,

  state: {
    record: null,
    owner: null,
    loading: false,
    statusLoading: false,
    addresses: [],
    addressLoading: false,
  },

  getters: {
    record: (state) => state.record,
    loading: (state) => !!state.loading,
    owner: (state) => state.owner,
    statusLoading: (state) => !!state.statusLoading,
    addresses: (state) => state.addresses || [],
    addressLoading: (state) => !!state.addressLoading,
  },

  mutations: {
    CHANGE_STATUS_STARTED(state) {
      state.statusLoading = true;
    },
    CHANGE_STATUS_SUCCESS(state) {
      state.statusLoading = false;
    },
    CHANGE_STATUS_ERROR(state) {
      state.statusLoading = false;
    },

    FIND_STARTED(state) {
      state.record = null;
      state.loading = true;
    },
    FIND_SUCCESS(state, payload) {
      state.record = payload;
      state.loading = false;
    },
    FIND_ERROR(state) {
      state.record = null;
      state.loading = false;
    },

    FIND_ADDRESSES_STARTED(state) {
      state.addresses = [];
      state.addressLoading = true;
    },
    FIND_ADDRESSES_SUCCESS(state, payload) {
      state.addresses = payload;
      state.addressLoading = false;
    },
    FIND_ADDRESSES_ERROR(state) {
      state.addresses = [];
      state.addressLoading = false;
    },
  },

  actions: {
    async doFind({ commit, rootGetters }, id) {
      try {
        commit('FIND_STARTED');
        // const record = await UserService.find(id);
        const record = await FirebaseRepository.findDocument('users', id);
        commit('FIND_SUCCESS', record);
      } catch (error) {
        Errors.handle(error);
        commit('FIND_ERROR');
        rootGetters[`user/list/type`] == 'admin'
          ? router.push('/admins')
          : router.push('/users')
      }
    },

    async doFindAdmin({ commit }, id) {
      try {
        commit('FIND_STARTED');
        const record = await UserService.findAdminById(id);
        commit('FIND_SUCCESS', record);
      } catch (error) {
        Errors.handle(error);
        commit('FIND_ERROR');
        router.push('/admins');
      }
    },

    async doFindUser({ commit }, id) {
      try {
        commit('FIND_STARTED');
        const record = await UserService.findUserById(id);
        commit('FIND_SUCCESS', record);
      } catch (error) {
        Errors.handle(error);
        commit('FIND_ERROR');
        router.push('/users');
      }
    },   
  },
};
