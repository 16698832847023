// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyDEfDWaD33jyoizzowY3rkY8KpLaDlXbDE',
  authDomain: 'aracia-org.firebaseapp.com',
  databaseURL: 'https://aracia-org-default-rtdb.firebaseio.com',
  projectId: 'aracia-org',
  storageBucket: 'aracia-org.appspot.com',
  messagingSenderId: '728466447303',
  appId: '1:728466447303:web:1e34d90f2c6553d39657e2',
  measurementId: 'G-DT0V6YL8SL'
}

const REGION = 'europe-west1'
const BASE_URL = `https://${REGION}-${
  firebaseConfig.projectId
}.cloudfunctions.net`

// #region [ Cloud Functions ]
  const backendUrl = BASE_URL
  const backendUrlUser = `${BASE_URL}/user`
  const backendUrlEvent = `${BASE_URL}/event`
  const backendUrlSettings = `${BASE_URL}/settings`
  // #endregion

// App Engine
// const backendUrl = `<insert app engine url here>`

export default {
  firebaseConfig,
  REGION,
  BASE_URL,
  backendUrl,
  backendUrlUser,
  backendUrlEvent,
  backendUrlSettings
}
