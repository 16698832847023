/* eslint-disable */     
import { UserService } from '@/store/user/user-service';
import Message from '@/shared/message/toastify';
import Errors from '@/shared/error/errors';
import { i18n } from '@/locales/i18n';
// import { routerAsync } from '@/app-module';
import router from '@/router'
// import FirebaseRepository from '@/shared/firebase/firebase-repository';
import PermissionChecker from '@/security/permission-checker';
import Permissions from '@/security/permissions';

export default {
  namespaced: true,

  state: {
    findLoading: false,
    saveLoading: false,
    record: null,
  },

  getters: {
    record: (state) => state.record,
    findLoading: (state) => !!state.findLoading,
    saveLoading: (state) => !!state.saveLoading,
  },

  mutations: {
    RESET(state) {
      state.findLoading = false;
      state.saveLoading = false;
      state.record = null;
    },

    FIND_STARTED(state) {
      state.record = null;
      state.findLoading = true;
    },

    FIND_SUCCESS(state, payload) {
      state.record = payload;
      state.findLoading = false;
    },

    FIND_ERROR(state) {
      state.record = null;
      state.findLoading = false;
    },

    CREATE_STARTED(state) {
      state.saveLoading = true;
    },

    CREATE_SUCCESS(state) {
      state.saveLoading = false;
    },

    CREATE_ERROR(state) {
      state.saveLoading = false;
    },

    UPDATE_STARTED(state) {
      state.saveLoading = true;
    },

    UPDATE_SUCCESS(state) {
      state.saveLoading = false;
    },

    UPDATE_ERROR(state) {
      state.saveLoading = false;
    },
  },

  actions: {
    doNew({ commit }) {
      commit('RESET');
    },

    async doFindAdmin({ commit }, id) {
      try {
        commit('FIND_STARTED');
        const record = await UserService.findAdminById(id);
        // const record = await FirebaseRepository.findDocument('users', id);
        commit('FIND_SUCCESS', record);
      } catch (error) {
        Errors.handle(error);
        commit('FIND_ERROR');
        router.push('/admins');
      }
    },

    async doFindUser({ commit }, id) {
      try {
        commit('FIND_STARTED');
        const record = await UserService.findUserById(id);
        // const record = await FirebaseRepository.findDocument('users', id);
        commit('FIND_SUCCESS', record);
      } catch (error) {
        Errors.handle(error);
        commit('FIND_ERROR');
        router.push('/users');
      }
    },

    async doCreate({ commit, rootGetters }, values) {
      try {
        commit('CREATE_STARTED');
        const record = await UserService.create(values);
        commit('CREATE_SUCCESS');
        Message.success(i18n('admin.create.success'));
        router.push('/admins');

        // const currentUser = rootGetters['auth/currentUser'];
        // const hasPermissionToAccessPages = new PermissionChecker(currentUser).match(Permissions.values.pagesAccessRead)

        // if (hasPermissionToAccessPages) {
        //   router.push(`/admins/${record.id}/access-pages`);
        // } else {
        //   router.push('/admins');
        // }
      } catch (error) {
        Errors.handle(error);
        commit('CREATE_ERROR');
      }
    },

    async doUpdate({ commit, rootGetters, dispatch }, { id, values }) {
      try {
        commit('UPDATE_STARTED');
        await UserService.edit(id, values);
        commit('UPDATE_SUCCESS');
        const currentUser = rootGetters['auth/currentUser'];
        if (currentUser.id === id) {
          dispatch('auth/doRefreshCurrentUser');
        }
        Message.success(i18n('admin.update.success'));
        router.push('/admins');
      } catch (error) {
        Errors.handle(error);
        commit('UPDATE_ERROR');
      }
    },

    async doAcceptUser({ commit, rootGetters, dispatch }, { id, route = false }) {
      try {
        commit('UPDATE_STARTED');
        await UserService.accept(id);
        commit('UPDATE_SUCCESS');
        Message.success(i18n('user.update.success'));

        if (route) {
          router.push('/users');
        } else {
          dispatch(`user/list/doFetch`, {
            type: 'user',
            filter: rootGetters[`user/list/filter`], 
            pagination: rootGetters[`user/list/pagination`], 
          }, { root: true });
        }
      } catch (error) {
        Errors.handle(error);
        commit('UPDATE_ERROR');
      }
    },

    async doRejectUser({ commit, rootGetters, dispatch }, { id, route = false }) {
      try {
        commit('UPDATE_STARTED');
        await UserService.reject(id);
        commit('UPDATE_SUCCESS');
        Message.success(i18n('user.update.success'));

        if (route) {
          router.push('/users');
        } else {
          dispatch(`user/list/doFetch`, {
            type: 'user',
            filter: rootGetters[`user/list/filter`], 
            pagination: rootGetters[`user/list/pagination`], 
          }, { root: true });
        }
      } catch (error) {
        Errors.handle(error);
        commit('UPDATE_ERROR');
      }
    },

    async doSetAdminAccess({ commit, rootGetters, dispatch }, { id, pagesAccess }) {
      try {
        commit('UPDATE_STARTED');
        await UserService.setAdminAccess(id, pagesAccess);
        commit('UPDATE_SUCCESS');
        const currentUser = rootGetters['auth/currentUser'];
        if (currentUser.id === id) {
          dispatch('auth/doRefreshCurrentUser');
        }
        Message.success(i18n('admin.update.success'));
        router.push('/admins');
      } catch (error) {
        Errors.handle(error);
        commit('UPDATE_ERROR');
      }
    },
  },
};
