/* eslint-disable */
import config from '@/config'
import gql from 'graphql-tag'
import GraphqlClient from '@/shared/graphql/client'
const graphqlClient = GraphqlClient.initApolloClient(config.backendUrlSettings)

export class SettingService {
//#region [ General Settings ]  
  /**
   * Update General Settings
   * - setSettings(settingsInput: SettingsInput!): JSON!
   * @param {Object} settings 
   * @param {String} settings.email
   * @param {String} settings.phoneNumber
   * @param {String} settings.address
   * @param {String} settings.whatsapp
   * @param {String} settings.instagram
   * @param {String} settings.snapchat
   * @param {String} settings.facebook
   * @param {String} settings.twitter
   * @param {String} settings.linkedin
   * @param {String} settings.pinterest
   * @param {String} settings.tiktok
   * @param {String} settings.youtube
   * @returns {Promise<JSON>}
   */
  static async updateGeneralSettings(settings) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation SETTINGS_GENERAL_UPDATE($settingsInput: SettingsInput!) {
          setSettings(settingsInput: $settingsInput)
        }
      `,

      variables: {
        settingsInput: settings
      }
    })

    if (response.data.setSettings && response.data.setSettings.status) {
      return response.data.setSettings.result
    } else {
      console.error(response.data.setSettings.error);
      throw response.data.setSettings.error
    }
  }  

  /**
   * Find General Settings
   * - findSettings: Settings!
   * @returns {Promise<Settings>}
   */
  static async findGeneralSettings() {
    const response = await graphqlClient.query({
      query: gql`
        query SETTINGS_GENERAL_FIND {
          findSettings {
            id
            email
            phoneNumber
            whatsapp
            address
            instagram
            snapchat
            facebook
            twitter
            linkedin
            pinterest
            tiktok
            youtube
          }
        }
      `
    })

    return response.data.findSettings
  }
//#endregion

//#region [ Privacy Privacy & Terms And Conditions ]
  /**
   * Update terms and conditions
   * - setTermsAndConditions(termsAndConditions: NameInput!): JSON!
   * @param {Object} termsAndConditions 
   * @param {String} termsAndConditions.en
   * @param {String} termsAndConditions.ar
   * @returns 
   */
  static async updateTermsAndConditions(termsAndConditions) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation TERMS_AND_CONDITIONS_UPDATE($termsAndConditions: NameInput!) {
          setTermsAndConditions(termsAndConditions: $termsAndConditions)
        }
      `,

      variables: {
        termsAndConditions
      }
    })

    if (response.data.setTermsAndConditions && response.data.setTermsAndConditions.status) {
      return response.data.setTermsAndConditions.result
    } else {
      console.error(response.data.setTermsAndConditions.error);
      throw response.data.setTermsAndConditions.error
    }
  }

  /**
   * - findTermsAndConditions: Name!
   * @returns {Promise<Name>}
   */
  static async findTermsAndConditions() {
    const response = await graphqlClient.query({
      query: gql`
        query TERMS_AND_CONDITIONS_FIND {
          findTermsAndConditions {
            en
            ar
          }
        }
      `
    })

    return response.data.findTermsAndConditions
  }
//#endregion

}
