/* eslint-disable */ 
import attendeeListStore from '@/store/attendee/attendee-list-store';
import attendeeFormStore from '@/store/attendee/attendee-form-store';

export default {
  namespaced: true,

  modules: {
    list: attendeeListStore,
    form: attendeeFormStore
  },
};
