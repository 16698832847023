/* eslint-disable */
// import { routerAsync } from '@/app-module';
import { UserService } from '@/store/user/user-service';
import Message from '@/shared/message/toastify';
import Errors from '@/shared/error/errors';
import { i18n } from '@/locales/i18n';
// import router from '@/router';

export default {
  namespaced: true,

  state: {
    loading: false,
  },

  getters: {
    loading: (state) => !!state.loading,
  },

  mutations: {
    DESTROY_ALL_STARTED(state) {
      state.loading = true;
    },

    DESTROY_ALL_SUCCESS(state) {
      state.loading = false;
    },

    DESTROY_ALL_ERROR(state) {
      state.loading = false;
    },

    DESTROY_STARTED(state) {
      state.loading = true;
    },

    DESTROY_SUCCESS(state) {
      state.loading = false;
    },

    DESTROY_ERROR(state) {
      state.loading = false;
    },
  },

  actions: {
    async doDestroyAdmin({ commit, dispatch, rootGetters }, id) {
      try {
        commit('DESTROY_STARTED');

        await UserService.removeAdmin(id)

        commit('DESTROY_SUCCESS');
        Message.success(i18n('admin.destroy.success'))

        dispatch(`user/list/doFetch`, {
          type: 'admin',
          filter: rootGetters[`user/list/filter`], 
          pagination: rootGetters[`user/list/pagination`], 
        }, { root: true });
      } catch (error) {
        Errors.handle(error);
        commit('DESTROY_ERROR');
      }
    },

    async doDestroyUser({ commit, dispatch, rootGetters }, id) {
      try {
        commit('DESTROY_STARTED');

        await UserService.removeUser(id)

        commit('DESTROY_SUCCESS');
        Message.success(i18n('user.destroy.success'))
        
        dispatch(`user/list/doFetch`, {
          type: 'user',
          filter: rootGetters[`user/list/filter`], 
          pagination: rootGetters[`user/list/pagination`], 
        }, { root: true });
      } catch (error) {
        Errors.handle(error);
        commit('DESTROY_ERROR');
      }
    },


    async doDestroy({ commit, dispatch, rootGetters }, id) {
      try {
        commit('DESTROY_STARTED');
        const UserType = rootGetters[`user/list/type`]

        UserType == 'admin'
          ? await UserService.removeAdmin(id)
          : await UserService.removeUser(id)

        commit('DESTROY_SUCCESS');
        UserType == 'admin'
          ? Message.success(i18n('admin.destroy.success'))
          : Message.success(i18n('user.destroy.success'))
        
        dispatch(`user/list/doFetch`, {
          filter: rootGetters[`user/list/filter`], 
          pagination: rootGetters[`user/list/pagination`], 
          type: UserType
        }, { root: true });
      } catch (error) {
        Errors.handle(error);
        commit('DESTROY_ERROR');
      }
    },

    async doDestroyAll({ commit, dispatch, rootGetters }, ids) {
      try {
        commit('DESTROY_ALL_STARTED');
  
        await UserService.removeUsers(ids)

        commit('DESTROY_ALL_SUCCESS');
  
        // dispatch(`user/list/doUnselectAll`, null, {
        //   root: true,
        // });
        rootGetters[`user/list/type`] == 'admin'
          ? Message.success(i18n('admin.destroyAll.success'))
          : Message.success(i18n('user.destroyAll.success'))

        dispatch(`user/list/doFetch`, {
          filter: rootGetters[`user/list/filter`], 
          pagination: rootGetters[`user/list/pagination`], 
          type: rootGetters[`user/list/type`]
        }, { root: true });
      } catch (error) {
        Errors.handle(error);
        commit('DESTROY_ALL_ERROR');
      }
    },
  },
};
