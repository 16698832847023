/* eslint-disable */
export default class PermissionChecker {
  constructor(currentUser) {
    this.currentUser = currentUser;
    this.userPagesAccess = currentUser ? currentUser.pagesAccess : [];
    this.userRole = currentUser ? currentUser.role : null;
    // this.userRoles = currentUser ? currentUser.roles : [];
  }

  /**
   * Match current user permission 
   * @param {String} permission 
   * @returns {Boolean}
   */
  match(permission) {
    if (!permission || this.userRole === 'owner' || this.userRole === 'admin') {
      return true
    }

    if (!this.userPagesAccess) {
      return false;
    }

    if (Array.isArray(permission)) {
      if (!permission.length) {
        return false;
      }

      return permission.some((role) =>
        this.userPagesAccess.includes(role),
      );
    }

    return this.userPagesAccess.includes(permission);
  }

  rolesMatchOneOf(arg) {
    if (!this.userPagesAccess) {
      return false;
    }

    if (!arg) {
      return false;
    }

    if (Array.isArray(arg)) {
      if (!arg.length) {
        return false;
      }

      return arg.some((role) =>
        this.userRoles.includes(role),
      );
    }

    return this.userPagesAccess.includes(arg);
  }

  get isEmptyPermissions() {
    if (!this.isAuthenticated) {
      return false;
    }

    return !this.userRoles || !this.userRoles.length;
  }

  get isAuthenticated() {
    return !!this.currentUser && !!this.currentUser.id;
  }

  get isEmailVerified() {
    if (!this.isAuthenticated) {
      return false;
    }

    return this.currentUser.emailVerified;
  }
}
