<template>
  <div>
    <router-view />
    <ErrorNotify message="--text--"></ErrorNotify>
    <SuccessNotify message="--text--"></SuccessNotify>
    <!-- <SuccessNotifyWithButtons></SuccessNotifyWithButtons> -->
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'
// import firebase from 'firebase/compat/app'
// import 'firebase/compat/auth'
// import 'firebase/compat/firestore' // for authentication
import { mapGetters, mapActions } from 'vuex'
import { useStore } from '@/store'
import { getLanguageCode } from '@/locales/i18n'

export default defineComponent({
  async created() {
    // console.log(this.store)
    console.log('process.env =', process.env)
    // const promises = [
    //   new Promise(() => this.doInitFirebase()),
    //   new Promise(() => this.doInit())
    // ]
    // Promise.all(promises).then()
    await this.doInit()
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
    // if (!this.anonymousToken) {
    //   const firebaseUser = await firebase.auth().signInAnonymously()
    //   console.log(firebaseUser)
    // }
  },
  unmounted() {
    window.removeEventListener('resize', this.handleResize)
  },
  computed: {
    ...mapGetters({
      language: 'layout/currentLanguageCode'
    })
  },
  methods: {
    ...mapActions({
      doInitFirebase: 'auth/doInitFirebase',
      doInit: 'auth/doInit',
      resize: 'layout/resize'
    }),
    handleResize() {
      console.log({
        width: window.innerWidth,
        height: window.innerHeight
      })
      this.resize({
        width: window.innerWidth,
        height: window.innerHeight
      })
    }
  },
  watch: {
    // language(newVal) {
    //   if (newVal == 'ar') {
    //     document.getElementsByTagName('html')[0].setAttribute('dir', 'rtl')
    //     document.body.setAttribute('dir', 'rtl')
    //   }
    //   if (newVal == 'en') {
    //     document.getElementsByTagName('html')[0].setAttribute('dir', 'ltr')
    //     document.body.setAttribute('dir', 'rtl')
    //   }
    // }
  },
  setup() {
    const store = useStore()
    const anonymousToken = ref('')
    onMounted(() => {
      const language = getLanguageCode() || localStorage.getItem('language')
      console.log(language)
      if (language == 'ar') {
        document.body.setAttribute('dir', 'rtl')
      } else {
        document.body.setAttribute('dir', 'ltr')
      }
    })
    return {
      store,
      anonymousToken
    }
  }
})
</script>

<style lang="scss">
// @import url('https://fonts.googleapis.com/css2?family=Merienda&display=swap');
// @import url('epic-spinners/dist/lib/epic-spinners.min.css');
@import url('https://fonts.googleapis.com/css2?family=Tajawal:wght@200;300;400;500;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Kufi+Arabic:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
[dir='rtl'] body, body[dir='rtl'] * {
  font-family: 'Noto Kufi Arabic', sans-serif !important;
}
[dir='ltr'] body, body[dir='ltr'] * {
  font-family: 'Poppins', sans-serif !important;
}
</style>
