/* eslint-disable */
import crypto from '@/shared/crypto'
import config from '@/config'
import gql from 'graphql-tag';
import GraphqlClient from '@/shared/graphql/client'
const graphqlClient = GraphqlClient.initApolloClient(config.backendUrlUser)

import firebaseInit from '@/shared/firebase/firebase-init'
import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/auth'

export default class AuthService {
  static init() {
    return firebaseInit();
  }

  static onAuthStateChanged(callbackSuccess, callbackError) {
    return firebase.auth().onAuthStateChanged(callbackSuccess, callbackError);
  }

//#region [ Emails ]
  static async isEmailConfigured() {
    try {
      const response = await graphqlClient.query({
        query: gql`
          {
            authIsEmailConfigured
          }
        `,
      });
  
      return response.data.authIsEmailConfigured;
    } catch (error) {
      console.error('isEmailConfigured =', error)
      return false
    }
  }

  static async sendEmailVerification(authenticationUser) {
    if (await this.isEmailConfigured()) {
      return this.sendEmailVerificationFromBackend();
    }

    return this.sendEmailVerificationFromClient(authenticationUser);
  }

  static async sendEmailVerificationFromBackend() {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation AUTH_SEND_EMAIL_ADDRESS_VERIFICATION_EMAIL {
          authSendEmailAddressVerificationEmail
        }
      `,
    });

    return response.data.authSendEmailAddressVerificationEmail;
  }

  static async sendEmailVerificationFromClient(authenticationUser) {
    return authenticationUser.sendEmailVerification();
  }

  static async sendPasswordResetEmail(email) {
    const isEmailConfigured = await this.isEmailConfigured()

    if (isEmailConfigured) {
      return await this.sendPasswordResetEmailFromBackend(email);
    }

    await this.sendPasswordResetEmailFromClient(email);
  }

  static async sendPasswordResetEmailFromBackend(email) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation AUTH_SEND_PASSWORD_RESET_EMAIL(
          $email: String!
        ) {
          authSendPasswordResetEmail(email: $email)
        }
      `,
      variables: {
        email,
      },
    });

    return response.data.authSendPasswordResetEmail;
  }

  static async sendPasswordResetEmailFromClient(email) {
    console.log(`sendPasswordResetEmailFromClient`);
    await firebase.auth().sendPasswordResetEmail(email);
  }
//#endregion

  static async registerWithEmailAndPassword(email, password) {
    const credentials = await firebase.auth().createUserWithEmailAndPassword(email, password);
    this.sendEmailVerification(credentials.user);
    return credentials.user;
  }

  static async signinWithSocial(provider, rememberMe = false) {
    const persistence = rememberMe
      ? firebase.auth.Auth.Persistence.LOCAL
      : firebase.auth.Auth.Persistence.SESSION;

    await firebase.auth().setPersistence(persistence);

    const providers = {
      google: firebase.auth.GoogleAuthProvider,
      facebook: firebase.auth.FacebookAuthProvider,
      twitter: firebase.auth.TwitterAuthProvider,
    };

    return firebase.auth().signInWithPopup(new providers[provider]());
  }

  static async signinWithEmailAndPassword(email, password, rememberMe = false) {
    const persistence = rememberMe
      ? firebase.auth.Auth.Persistence.LOCAL
      : firebase.auth.Auth.Persistence.SESSION;

    await firebase.auth().setPersistence(persistence);
    return firebase.auth().signInWithEmailAndPassword(email, password);
  }

  static async reauthenticateWithStorageToken() {
    try {
      let token = crypto.decryptString(localStorage.getItem('token'), 'secret-token')

      if (!token) {
        token = await this.generateCustomToken()
      }

      try {
        return await firebase.auth().signInWithCustomToken(token);
      } catch (error) {
        if (error && error.code && error.code === 'auth/invalid-custom-token') {
          token = await this.generateCustomToken()
          return firebase.auth().signInWithCustomToken(token);
        }
        throw error
      }
    } catch (error) {
      console.error({ code: error.code, message: error.message });
    }
  }

  static async generateCustomToken() {
    try {
      const response = await graphqlClient.query({
        query: gql`
          {
            authStorageToken
          }
        `,
      });
      const token = response.data.authStorageToken;
      localStorage.setItem('token', crypto.encryptString(token, 'secret-token'));
      return token
    } catch (error) {
      console.error(error);
    }
  }

  static signout() {
    return firebase.auth().signOut();
  }

  static async fetchMe() {
    // const response = await graphqlClient.query({
    //   query: gql`
    //     {
    //       authMe {
    //         id
    //         authenticationUid
    //         fullName
    //         firstName
    //         lastName
    //         phoneNumber
    //         countryCode
    //         accountType
    //         lang
    //         email
    //         roles
    //         avatars {
    //           id
    //           name
    //           publicUrl
    //         }
    //       }
    //     }
    //   `,
    // });

    const response = await graphqlClient.query({
      query: gql`
        {
          fetchMe {
            id
            email
            fullName
            role
            isRemoved

            # phoneNumber
            # avatar
            # pagesAccess
            # deviceTokens
            # status
            # lang

            createdBy
            createdAt
            updatedBy
            updatedAt
          }
        }
      `,
    });

    return response.data.fetchMe;
  }
  
  /**
   * Update admin profile
   * - editMe(adminUpdate: AdminUpdate): JSON!
   * @param {Object} profile 
   * @param {String} profile.fullName 
   * @param {String} profile.phoneNumber 
   * @returns 
   */
  static async updateProfile({
    fullName,
    phoneNumber
    // avatar,
    // email,
    // pagesAccess,
    // lang
  }) {
    const response = await graphqlClient.mutate({
      // mutation: gql`
      //   mutation AUTH_UPDATE_PROFILE($profile: UserProfileInput!) {
      //     authUpdateProfile(profile: $profile)
      //   }
      // `,

      mutation: gql`
        mutation AUTH_UPDATE_PROFILE($adminUpdate: AdminUpdate!) {
          editMe(adminUpdate: $adminUpdate)
        }
      `,

      variables: {
        adminUpdate: {
          fullName,
          phoneNumber
          // avatar,
          // email,
          // pagesAccess,
          // lang
        },
      },
    });

    return response.data.editMe;
    // return response.data.authUpdateProfile;
  }

  static async changeMyPassword(oldPassword, newPassword) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation AUTH_CHANGE_PROFILE_PASSWORD($oldPassword: String!, $newPassword: String!) {
          resetMyPassword(oldPassword: $oldPassword, newPassword: $newPassword)
        }
      `,

      variables: {
        oldPassword,
        newPassword
      },
    });

    return response.data.resetMyPassword;
  }

  static fetchLocalCurrentUser() {
    const currentUser = localStorage.getItem('currentUser')
    return currentUser 
      ? crypto.decryption(currentUser, 'secret-c-u')
      : null
  }

  static saveLocalCurrentUser(currentUser) {
    if (currentUser) {
      localStorage.setItem(
        'currentUser', 
        crypto.encryption(currentUser, 'secret-c-u')
      );
    }
  }
}
