import { createStore } from 'vuex'
import { vuexfireMutations } from 'vuexfire'
import main from './main'
import sideMenu from './side-menu'
import layout from '@/store/layout-store'
import auth from '@/auth/auth-store'
import home from '@/store/home/home-store'
import user from '@/store/user/user-store'
import event from '@/store/event/event-store'
import attendee from '@/store/attendee/attendee-store'
import setting from '@/store/setting/setting-store'
import realtime from '@/store/realtime-store'

const store = createStore({
  modules: {
    main,
    sideMenu,
    layout,
    auth,
    home,
    user,
    event,
    attendee,
    setting,
    realtime
  },
  mutations: {
    ...vuexfireMutations
  }
})

export function useStore() {
  return store
}

export default store
