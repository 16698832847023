/* eslint-disable */     
import { SettingService } from '@/store/setting/setting-service';
import Message from '@/shared/message/toastify';
import Errors from '@/shared/error/errors';
import { i18n } from '@/locales/i18n';
// import { routerAsync } from '@/app-module';
import router from '@/router'
// import FirebaseRepository from '@/shared/firebase/firebase-repository'

export default {
  namespaced: true,

  state: {
    findLoading: false,
    saveLoading: false,
    record: null,
  },

  getters: {
    record: (state) => state.record,
    findLoading: (state) => !!state.findLoading,
    saveLoading: (state) => !!state.saveLoading,
  },

  mutations: {
    RESET(state) {
      state.findLoading = false;
      state.saveLoading = false;
      state.record = null;
    },

    FIND_STARTED(state) {
      state.record = null;
      state.findLoading = true;
    },
    FIND_SUCCESS(state, payload) {
      state.record = payload;
      state.findLoading = false;
    },
    FIND_ERROR(state) {
      state.record = null;
      state.findLoading = false;
    },

    UPDATE_STARTED(state) {
      state.saveLoading = true;
    },
    UPDATE_SUCCESS(state) {
      state.saveLoading = false;
    },
    UPDATE_ERROR(state) {
      state.saveLoading = false;
    },
  },

  actions: {
    doNew({ commit }) {
      commit('RESET');
    },

    async doFind({ commit }) {
      try {
        commit('FIND_STARTED');
        const record = await SettingService.findGeneralSettings();
        commit('FIND_SUCCESS', record);
      } catch (error) {
        Errors.handle(error);
        commit('FIND_ERROR');
        router.push('/');
      }
    },

    async doUpdate({ commit }, values) {
      try {
        commit('UPDATE_STARTED');
        await SettingService.updateGeneralSettings(values);
        commit('UPDATE_SUCCESS');
        Message.success(i18n('settings.update.success'));
      } catch (error) {
        Errors.handle(error);
        commit('UPDATE_ERROR');
        router.push('/');
      }
    },
  },
};
