/* eslint-disable */
import config from '@/config'
import gql from 'graphql-tag'
import GraphqlClient from '@/shared/graphql/client'
const graphqlClient = GraphqlClient.initApolloClient(config.backendUrlUser)

export class UserService {
//#region [ Mutation APIs ]
  /**
   * Enable user in authentication table and firestore database
   * @param {String[]} ids
   * @returns
   */
  static async enable(ids) {
    return this._changeStatus(ids, true)
  }
  /**
   * Disable user in authentication table and firestore database
   * @param {String[]} ids
   * @returns
   */
  static async disable(ids) {
    return this._changeStatus(ids, false)
  }
  static async _changeStatus(ids, disabled) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation USER_CHANGE_STATUS(
          $adminsIds: [String!]!
          $status: Boolean!
        ) {
          setAdminsStatus(adminsIds: $adminsIds, status: $status)
        }
      `,

      variables: {
        adminsIds: ids,
        status: !!disabled
      }
    })

    // return response.data.setAdminsStatus
    if (response.data.setAdminsStatus && response.data.setAdminsStatus.status) {
      return response.data.setAdminsStatus.result
    } else {
      console.error(response.data.setAdminsStatus.error);
      throw response.data.setAdminsStatus.error
    }
  }

  static async accept(id) {
    return this._decidePendingUser(id, true)
  }
  static async reject(id) {
    return this._decidePendingUser(id, false)
  }
  /**
   * 
   * - decidePendingUser(userId: String!, acceptUser: Boolean!): JSON!
   * @param {String} userId 
   * @param {Boolean} acceptUser 
   * @returns {Promise<JSON>}
   */
  static async _decidePendingUser(userId, acceptUser) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation USER_CHANGE_STATUS(
          $userId: String!
          $acceptUser: Boolean!
        ) {
          decidePendingUser(userId: $userId, acceptUser: $acceptUser)
        }
      `,

      variables: {
        userId,
        acceptUser: !!acceptUser
      }
    })

    // return response.data.decidePendingUser
    if (response.data.decidePendingUser && response.data.decidePendingUser.status) {
      return response.data.decidePendingUser.result
    } else {
      console.error(response.data.decidePendingUser.error);
      throw response.data.decidePendingUser.error
    }
  }

  /**
   * Set page access permissions for the admin
   * - setAdminAccess(adminId: String!, pagesAccess: [String]!): JSON!
   * @param {String} id 
   * @param {String[]} pagesAccess 
   * @returns 
   */
  static async setAdminAccess(id, pagesAccess) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation USER_CHANGE_ROLES(
          $adminId: String!
          $pagesAccess: [String]!
        ) {
          setAdminAccess(adminId: $adminId, pagesAccess: $pagesAccess)
        }
      `,

      variables: {
        adminId: id,
        pagesAccess
      }
    })

    // return response.data.setAdminAccess
    if (response.data.setAdminAccess && response.data.setAdminAccess.status) {
      return response.data.setAdminAccess.result
    } else {
      console.error(response.data.setAdminAccess.error);
      throw response.data.setAdminAccess.error
    }
  }


  // * @param {String} data.userName
  // * @param {String} data.phoneNumber
  // * @param {String[]} data.pagesAccess
  // * @param {String} data.avatar
  // * @param {'en'|'ar' } data.lang
  /**
   * Create new admin
   * - createAdmin(adminInput: AdminInput!): JSON!
   * @param {Object} data
   * @param {String} data.email
   * @param {String} data.password
   * @returns {Promise<JSON>}
   */
  static async create(data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation USER_CREATE($adminInput: AdminInput!) {
          createAdmin(adminInput: $adminInput)
        }`,
      variables: {
        adminInput: data
      }
    })

    // return response.data.createAdmin
    if (response.data.createAdmin && response.data.createAdmin.status) {
      return response.data.createAdmin.result
    } else {
      console.error(response.data.createAdmin.error);
      throw response.data.createAdmin.error
    }
  }

  /**
   * Update admin
   * @param {String} id
   * @param {Object} data
   * @param {String} data.email
   * @param {String} data.userName
   * @param {String} data.phoneNumber
   * @param {String[]} data.pagesAccess
   * @param {String} data.avatar
   * @param {'en'|'ar' } data.lang
   * @returns {Promise<JSON>}
   */
  static async update(id, data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation USER_EDIT($adminId: String!, $adminUpdate: AdminUpdate!) {
          editAdmin(adminId: $adminId, adminUpdate: $adminUpdate)
        }
      `,

      variables: {
        adminId: id,
        adminUpdate: data
      }
    })

    if (response.data.editAdmin && response.data.editAdmin.status) {
      return response.data.editAdmin.result
    } else {
      console.error(response.data.editAdmin.error);
      throw response.data.editAdmin.error
    }
    // return response.data.editAdmin
  }

  /**
   * Delete Admin
   * - removeAdmin(adminId: String!): JSON!
   * @param {String} adminId 
   * @returns {Promise<JSON>}
   */
  static async removeAdmin(adminId) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation USER_DESTROY($adminId: String!) {
          removeAdmin(adminId: $adminId)
        }
      `,

      variables: {
        adminId
      }
    })

    if (response.data.removeAdmin && response.data.removeAdmin.status) {
      return response.data.removeAdmin.result
    } else {
      console.error(response.data.removeAdmin.error);
      throw response.data.removeAdmin.error
    }
  }

  /**
   * Delete User / Member
   * - removeUser(userId: String!): JSON!
   * @param {String} userId 
   * @returns {Promise<JSON>} 
   */
  static async removeUser(userId) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation USER_DESTROY($userId: String!) {
          removeUser(userId: $userId)
        }
      `,

      variables: {
        userId
      }
    })

    if (response.data.removeUser && response.data.removeUser.status) {
      return response.data.removeUser.result
    } else {
      console.error(response.data.removeUser.error);
      throw response.data.removeUser.error
    }
  }

  // static async remove(emails, roles, all = false) {
  //   const response = await graphqlClient.mutate({
  //     mutation: gql`
  //       mutation USER_REMOVE(
  //         $emails: [String!]!
  //         $roles: [String!]!
  //         $all: Boolean
  //       ) {
  //         iamRemove(
  //           emails: $emails
  //           roles: $roles
  //           all: $all
  //         )
  //       }
  //     `,

  //     variables: {
  //       emails,
  //       roles,
  //       all
  //     }
  //   })

  //   return response.data.iamRemove
  // }

  // static async destroy(id) {
  //   const response = await graphqlClient.mutate({
  //     mutation: gql`
  //       mutation USER_DESTROY($id: String!) {
  //         iamDestroy(id: $id)
  //       }
  //     `,

  //     variables: {
  //       id
  //     }
  //   })
  //   return response.data.iamDestroy
  // }
//#endregion

//#region [ Query APIs ]
  // static async find(id) {
  //   const response = await graphqlClient.query({
  //     query: gql`
  //       query USER_FIND($id: String!) {
  //         iamFind(id: $id) {
  //           id
  //           email
  //           userName
  //           phoneNumber
  //           avatar
  //           listedProductsCount
  //           authenticationUid
  //           deviceTokens
  //           lang
  //           role
  //           status
  //           pagesAccess
  //           notification
  //           createdBy
  //           createdAt
  //           updatedBy
  //           updatedAt
  //         }
  //       }
  //     `,

  //     variables: {
  //       id
  //     }
  //   })

  //   return response.data.iamFind
  // }

  /**
   * Find User By ID
   * - findUserById(userId: String!): User!
   * @param {String} userId 
   * @returns {Promise<User>}
   */
  static async findUserById(userId) {
    const response = await graphqlClient.query({
      query: gql`
        query USER_FIND($userId: String!) {
          findUserById(userId: $userId) {
            id
            fullName
            phoneNumber
            email
            role
            status
            type
            gender
            state
            city
            zipCode
            isRemoved
            familyMembersCount
            underEightCount
            overFiftyCount

            # avatar
            # listedProductsCount
            # authenticationUid
            # deviceTokens
            # lang
            # pagesAccess
            # notification

            createdBy
            createdAt
            updatedBy
            updatedAt
          }
        }
      `,

      variables: {
        userId
      }
    })

    return response.data.findUserById
  }

  /**
   * Find Admin By ID
   * - findAdminById(adminId: String!): Admin!
   * @param {String} adminId 
   * @returns {Promise<Admin>}
   */
   static async findAdminById(adminId) {
    const response = await graphqlClient.query({
      query: gql`
        query USER_FIND($adminId: String!) {
          findAdminById(adminId: $adminId) {
            id
            email
            fullName
            role
            isRemoved

            # phoneNumber
            # avatar
            # pagesAccess
            # deviceTokens
            # status
            # lang

            createdBy
            createdAt
            updatedBy
            updatedAt
          }
        }
      `,

      variables: {
        adminId
      }
    })

    return response.data.findAdminById
  }

  /**
   * Retrieve admins with pagination
   * - listAdmins(filter: [Filter]!, pagination: PaginationInput!): [Admin]!
   * @param {Object[]} filter
   * @param {String} filter.key
   * @param {String} filter.value
   * @param {String[]} filter.values
   * @param {'less'|'lessEqual'|'greater'|'greaterEqual'|'equal'|'notEqual'|'startsWith|in|notIn|arrayContains|arrayContainsAny'} filter.operator
   * 
   * @param {Object} pagination
   * @param {Boolean} pagination.firstPage
   * @param {Int} pagination.limit
   * @param {JSON} pagination.doc
   * @param {'next'|'prev'} pagination.action
   * @param {'asc'|'desc'} pagination.sortType
   * @param {String} pagination.orderBy
   * @returns {Promise<Admin[]>}
   */
  static async listAdmins(filter, pagination) {
    const response = await graphqlClient.query({
      query: gql`
        query USER_LIST_USERS(
          $filter: [Filter]!,
          $pagination: PaginationInput!
        ) {
          listAdmins(filter: $filter, pagination: $pagination) {
            id
            email
            fullName
            phoneNumber
            role
            isRemoved

            # avatar
            # pagesAccess
            # deviceTokens
            # status
            # lang

            createdBy
            createdAt
            updatedBy
            updatedAt
          }
        }
      `,

      variables: {
        filter,
        pagination
      }
    })

    return response.data.listAdmins
  }

  /**
   * Retrieve users with pagination without admins 
   * - listUsers(filter: [Filter]!, pagination: PaginationInput!): [User]!
   * @param {Object[]} filter
   * @param {String} filter.key
   * @param {String} filter.value
   * @param {String[]} filter.values
   * @param {'less'|'lessEqual'|'greater'|'greaterEqual'|'equal'|'notEqual'|'startsWith|in|notIn|arrayContains|arrayContainsAny'} filter.operator
   * 
   * @param {Object} pagination
   * @param {Boolean} pagination.firstPage
   * @param {Int} pagination.limit
   * @param {JSON} pagination.doc
   * @param {'next'|'prev'} pagination.action
   * @param {'asc'|'desc'} pagination.sortType
   * @param {String} pagination.orderBy
   * @returns {Promise<User[]>}
   */
  static async listUsers(filter, pagination) {
    const response = await graphqlClient.query({
      query: gql`
        query USER_LIST_USERS(
          $filter: [Filter]!, 
          $pagination: PaginationInput!
        ) {
          listUsers(filter: $filter, pagination: $pagination) {
            id
            fullName
            phoneNumber
            email
            role
            status
            type
            gender
            state
            city
            zipCode
            isRemoved
            familyMembersCount
            underEightCount
            overFiftyCount

            # avatar
            # listedProductsCount
            # authenticationUid
            # deviceTokens
            # lang
            # pagesAccess
            # notification

            createdBy
            createdAt
            updatedBy
            updatedAt
          }
        }
      `,
//bankAccounts {
//   id
//   bankName
//   iban
//   fullName
//   isDefault
// }
// addresses {
//   id
//   name
//   description
//   lat
//   long
//   phoneNumber
//   isDefault
// }
      variables: {
        filter,
        pagination
      }
    })

    return response.data.listUsers
  }
//#endregion
}
