import Permissions from '@/security/permissions'

const state = () => {
  return {
    menu: [
      // {
      //   icon: 'HomeIcon',
      //   pageName: 'home',
      //   title: 'Dashboard'
      // },
      {
        icon: 'UserCheckIcon',
        pageName: 'admins',
        title: 'Admins',
        permission: Permissions.values.adminRead
      },
      {
        icon: 'UsersIcon',
        pageName: 'users',
        title: 'Users',
        permission: Permissions.values.userRead
      },
      {
        icon: 'CalendarIcon',
        pageName: 'events',
        title: 'Events'
        // permission: Permissions.values.eventRead
      },
      {
        icon: 'SettingsIcon',
        pageName: 'settings',
        title: 'Settings',
        permission: Permissions.values.settingRead
      },
      'devider'
    ]
  }
}

const getters = {
  menu: state => state.menu
}
const mutations = {}
const actions = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
